import { Fragment } from "react";

import useApplication from "../../store/useApplication";
import { PAGES } from "../../types";
import BreadcrumbSeparator from "./BreadcrumbSeparator";
import {
  BreadcrumbLabel,
  BreadcrumbMobileButton,
  BreadcrumbsDesktop,
  BreadcrumbsMobile,
  StyledBreadcrumbs,
} from "./styles";

const Breadcrumbs = () => {
  const setPage = useApplication((state) => state.setPage);
  const setBrandId = useApplication((state) => state.setBrandId);
  const breadcrumbs = useApplication((state) => state.breadcrumbs);
  const setVariantId = useApplication((state) => state.setVariantId);
  const setVariants = useApplication((state) => state.setVariants);
  const setBreadcrumbs = useApplication((state) => state.setBreadcrumbs);
  const setPackages = useApplication((state) => state.setPackages);

  const onBrandClick = () => {
    setPage(PAGES.BRAND);
    setBrandId(null);
    setVariantId(null);
    setBreadcrumbs([]);
    setVariants([]);
    setPackages([]);
  };

  const onVariantClick = () => {
    setPage(PAGES.VARIANT);
    setVariantId(null);
    setPackages([]);
    setBreadcrumbs(breadcrumbs.slice(0, 1));
  };

  const isBrand = breadcrumbs.length === 1;

  return (
    <StyledBreadcrumbs>
      <BreadcrumbsDesktop>
        {breadcrumbs.length !== 0 && (
          <BreadcrumbLabel isClickable={true} onClick={onBrandClick}>
            Strona główna
          </BreadcrumbLabel>
        )}
        {breadcrumbs.length > 0 && <BreadcrumbSeparator />}
        {breadcrumbs.map((item, index) => {
          return (
            <Fragment key={index}>
              <BreadcrumbLabel
                isClickable={index !== breadcrumbs.length - 1}
                onClick={() => {
                  index !== breadcrumbs.length - 1 && onVariantClick();
                }}
              >
                {item.name}
              </BreadcrumbLabel>

              {index !== breadcrumbs.length - 1 && <BreadcrumbSeparator />}
            </Fragment>
          );
        })}
      </BreadcrumbsDesktop>
      {breadcrumbs.length > 0 && (
        <BreadcrumbsMobile>
          <BreadcrumbMobileButton
            onClick={() => (isBrand ? onBrandClick() : onVariantClick())}
          >
            <BreadcrumbSeparator isMobile />
          </BreadcrumbMobileButton>
          {breadcrumbs[breadcrumbs.length - 1].name}
        </BreadcrumbsMobile>
      )}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
