import styled from "styled-components";
import { BREAKPOINTS, layoutSpacing, Wrapper } from "../shared";

export const StyledFooter = styled.div`
  background: #006939;
  ${layoutSpacing};
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const FooterWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.MD}) {
    flex-direction: column;
    grid-gap: 32px;
  }
`;

export const FooterGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;

  grid-template-areas:
    "logo menu socials"
    "logo menu socials"
    "logo copyrights socials";

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-gap: 32px;
    grid-template-areas:
      "logo logo logo"
      "menu menu menu"
      "socials socials socials"
      "copyrights copyrights copyrights";
  }
`;

export const LogoArea = styled.div`
  grid-area: logo;
  display: flex;

  @media (max-width: ${BREAKPOINTS.MD}) {
    justify-content: center;
  }
`;

export const CopyrightsArea = styled.div`
  grid-area: copyrights;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const MenuArea = styled.div`
  grid-area: menu;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const SocialsArea = styled.div`
  grid-area: socials;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINTS.MD}) {
    justify-content: center;
  }
`;
