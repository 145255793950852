import styled, { css } from "styled-components";
import { BreadcrumbSeparatorProps } from "./types";

export const StyledBreadcrumbSeparator = styled.svg<BreadcrumbSeparatorProps>`
  ${(p) =>
    p.isMobile &&
    css`
      cursor: pointer;
      transform: rotate(180deg);
    `}
`;
