import axios from "axios";
import { Brands, Packages, Variants } from "../types";

export const getBrands = async () => {
  const brands = await axios.get(`${process.env.REACT_APP_API_URI}/api/brand`);

  const _brands: Brands = brands.data.map(
    ({ brandId, container, image, name, menu_order }: any) => {
      return {
        brandId: +brandId,
        container,
        image,
        order: +menu_order,
        name,
      };
    }
  );

  return _brands.sort((a, b) => a.order - b.order);
};

export const getVariants = async (brandId: any) => {
  const variants = await axios.get(
    `${process.env.REACT_APP_API_URI}/api/brand/${brandId}/variants`
  );

  const _variants: Variants = variants.data.map(
    ({
      variantId,
      container,
      image,
      name,
      menu_order,
      isNew,
      alcohol,
    }: any) => {
      return {
        variantId: +variantId,
        container,
        image,
        order: +menu_order,
        name,
        isNew,
        alcohol,
      };
    }
  );

  return _variants.sort((a, b) => a.order - b.order);
};

export const getPackages = async (variantId: any) => {
  const packages = await axios.get(
    `${process.env.REACT_APP_API_URI}/api/variant/${variantId}/packages`
  );

  const _packages: Packages = packages.data.map(
    ({
      container,
      count,
      image,
      menu_order,
      name,
      number,
      packageId,
      palletsize,
      png,
      png_size,
      psd,
      psd_size,
      realsize,
      tif,
      tif_size,
      variantId,
      volume,
    }: any) => {
      return {
        links: {
          tif: {
            name: tif,
            size: tif_size,
          },
          png: {
            name: png,
            size: png_size,
          },
          psd: {
            name: psd,
            size: psd_size,
          },
        },
        details: {
          count,
          realSize: realsize,
          palletSize: palletsize,
          number,
        },
        packageId: +packageId,
        container,
        image,
        order: +menu_order,
        name,
        variantId,
        volume,
      };
    }
  );

  return _packages.sort((a, b) => a.order - b.order);
};
