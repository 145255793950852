import { StateCreator } from "zustand";
import { BrandId, Brands } from "../../types";
import { MyState } from "../useApplication";

export interface BrandSlice {
  brands: Brands;
  setBrands: (brands: Brands) => void;
  brandId: BrandId;
  setBrandId: (brandId: BrandId) => void;
}

export const createBrandSlice: StateCreator<MyState, [], [], BrandSlice> = (
  set
) => ({
  brands: [],
  brandId: null,
  setBrands: (data) =>
    set((state) => {
      return {
        ...state,
        brands: data,
      };
    }),
  setBrandId: (brandId) =>
    set((state) => {
      return {
        ...state,
        brandId,
      };
    }),
});

export default createBrandSlice;
