import styled from "styled-components";

export const StyledRibbon = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: right;
`;

export const RibbonText = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 40px;
  transform: rotate(45deg);
  width: 134px;
  display: block;
  background: linear-gradient(
      159.84deg,
      rgba(0, 105, 57, 0.65) 12.27%,
      #006939 89.89%
    ),
    #d9d9d9;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 14px;
  right: -33px;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #0d8a50;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #0d8a50;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0%;
    top: 100%;
    z-index: -1;
    border-right: 3px solid #126b43;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #126b43;
  }
`;
