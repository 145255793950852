import { StateCreator } from "zustand";
import { VariantId, Variants } from "../../types";
import { MyState } from "../useApplication";

export interface VariantSlice {
  variantId: VariantId;
  setVariantId: (variantId: VariantId) => void;
  variants: Variants;
  setVariants: (variants: Variants) => void;
}

export const createVariantSlice: StateCreator<MyState, [], [], VariantSlice> = (
  set
) => ({
  variantId: null,
  variants: [],
  setVariantId: (variantId) =>
    set((state) => {
      return {
        ...state,
        variantId,
      };
    }),
  setVariants: (variants) =>
    set((state) => {
      return {
        ...state,
        variants,
      };
    }),
});

export default createVariantSlice;
