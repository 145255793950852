import { useEffect } from "react";
import { SwitchTransition } from "react-transition-group";
import Brand from "../../pages/Products/Product/Brand";
import Package from "../../pages/Products/Product/Package";
import Variant from "../../pages/Products/Product/Variant";
import useApplication from "../../store/useApplication";
import { PAGES } from "../../types";
import FadeTransition from "../FadeTransition";
import { StyledPageContent } from "./styles";

const getContent = (page: PAGES) => {
  switch (page) {
    case PAGES.BRAND:
      return <Brand />;
    case PAGES.VARIANT:
      return <Variant />;
    case PAGES.PACKAGE:
      return <Package />;
    default:
      return null;
  }
};

const PageContent = () => {
  const page = useApplication((state) => state.page);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [page]);

  return (
    <StyledPageContent>
      <SwitchTransition mode="out-in">
        <FadeTransition key={page} timeout={250} unmountOnExit mountOnEnter>
          {getContent(page)}
        </FadeTransition>
      </SwitchTransition>
    </StyledPageContent>
  );
};

export default PageContent;
