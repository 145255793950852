import { Link, Links, StyledMenu } from "./styles";

const Menu = () => (
  <StyledMenu>
    <Links>
      <Link
        target="_blank"
        href="https://carlsbergpolska.pl/polityka-prywatnosci/"
      >
        Polityka prywatności
      </Link>
      <Link target="_blank" href="https://carlsbergpolska.pl/polityka-cookie/">
        Polityka cookie
      </Link>
      <Link target="_blank" href="https://carlsbergpolska.pl/kontakt/kontakt/">
        Kontakt
      </Link>
    </Links>
  </StyledMenu>
);

export default Menu;
