import Loader from "./Loader";
import { StyledRoundButton, StyledRoundButtonImage } from "./styles";
import { RoundButtonProps } from "./types";

const RoundButton = ({ isLoading, onClick }: RoundButtonProps) => {
  return (
    <StyledRoundButton onClick={onClick}>
      {isLoading ? (
        <Loader />
      ) : (
        <StyledRoundButtonImage src={"./images/download-icon.png"} />
      )}
    </StyledRoundButton>
  );
};

export default RoundButton;
