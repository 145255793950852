import useApplication from "../../store/useApplication";
import { PAGES } from "../../types";
import { StyledPageHeader } from "./styles";

type Translation = { [key: string]: string };

const TRANSLATIONS: Translation = {
  [PAGES.BRAND]: "Markę",
  [PAGES.VARIANT]: "Wariant",
  [PAGES.PACKAGE]: "Opakowanie",
};

const PageHeader = () => {
  const page = useApplication((state) => state.page);

  return <StyledPageHeader>Wybierz {TRANSLATIONS[page]}</StyledPageHeader>;
};

export default PageHeader;
