import { Transition } from "react-transition-group";
import { Animated } from "./styles";

const FadeTransition = ({ children, ...rest }: any) => (
  <Transition {...rest} appear={true} unmountOnExit timeout={300}>
    {(state) => <Animated state={state}>{children}</Animated>}
  </Transition>
);

export default FadeTransition;
