import styled from "styled-components";
import { Label } from "../../../../../components/shared";

export const StyledDetails = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
`;

export const DetailItem = styled(Label)`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  align-items: center;
  font-weight: 700;
`;
