import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Carlsberg';
  src:url("./fonts/Carlsberg/CarlsbergSans-Light.eot");
  src:url("./fonts/Carlsberg/CarlsbergSans-Light.eot?iefix") format('eot'),url("./fonts/Carlsberg/CarlsbergSans-Light.woff") format('woff'),url("./fonts/Carlsberg/CarlsbergSans-Light.ttf") format('truetype'),url("./fonts/Carlsberg/CarlsbergSans-Light.svg#webfont") format('svg');
  font-weight: 400;
}

@font-face {
  font-family: 'Carlsberg';
  src:url("./fonts/Carlsberg/CarlsbergSans-Bold.eot");
  src:url("./fonts/Carlsberg/CarlsbergSans-Bold.eot?iefix") format('eot'),url("./fonts/Carlsberg/CarlsbergSans-Bold.woff") format('woff'),url("./fonts/Carlsberg/CarlsbergSans-Bold.ttf") format('truetype'),url("./fonts/Carlsberg/CarlsbergSans-Bold.svg#webfont") format('svg');
  font-weight: 700;
}

@font-face {
  font-family: 'Carlsberg';
  src:url("./fonts/Carlsberg/CarlsbergSans-Black.eot");
  src:url("./fonts/Carlsberg/CarlsbergSans-Black.eot?iefix") format('eot'),url("./fonts/Carlsberg/CarlsbergSans-Black.woff") format('woff'),url("./fonts/Carlsberg/CarlsbergSans-Black.ttf") format('truetype'),url("./fonts/Carlsberg/CarlsbergSans-Black.svg#webfont") format('svg');
  font-weight: 800;
}

body, html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Carlsberg;
  font-weight: 700;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  height: 100%;
}
#root {
  height: 100%;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 700;
}

button, input, textarea {
  font-family: CarlsbergSans;
}

* {
  box-sizing: border-box;
}
`;

export default GlobalStyle;
