import styled, { css } from "styled-components";
import { IsNew } from "../../types";
import { CARD_WIDTH } from "../shared";

export const StyledProductCard = styled.div<{ isNew?: IsNew }>`
  position: relative;
  width: ${CARD_WIDTH};
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shink: 0;
  min-height: 276px;
  align-items: center;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `};

  &:nth-child(5n + 1) {
    background: #4c8272;
  }

  &:nth-child(5n + 2) {
    background: #435051;
  }

  &:nth-child(5n + 3) {
    background: #72a49f;
  }

  &:nth-child(5n + 4) {
    background: #5e6b6b;
  }

  &:nth-child(5n + 5) {
    background: #396155;
  }
`;

export const Picutre = styled.div`
  position: relative;
  height: 163px;
  width: 100%;
`;

export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
  line-height: normal;
  margin: 24px 0 4px 0;
`;

export const Content = styled.div``;

export const getContainerSize = (container: string = "bottle") => {
  switch (container) {
    case "bottle":
      return css`
        height: 200px;
      `;

    case "can":
      return css`
        height: 200px;
      `;

    case "pack":
      return css`
        height: 150px;
      `;
  }
};

export const PicutreChild = styled.img<{ container?: string }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 333ms ease 0s;

  ${StyledProductCard}:hover & {
    bottom: 10px;
  }

  ${(p) => getContainerSize(p.container)}
`;
