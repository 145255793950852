import { StyledBreadcrumbSeparator } from "./styles";
import { BreadcrumbSeparatorProps } from "./types";

const BreadcrumbSeparator = ({ isMobile }: BreadcrumbSeparatorProps) => (
  <StyledBreadcrumbSeparator
    isMobile={isMobile}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="navigate_next_24px">
      <path
        id="icon/image/navigate_next_24px"
        d="M8.89626 5.5L7.60376 6.7925L11.8021 11L7.60376 15.2075L8.89626 16.5L14.3963 11L8.89626 5.5Z"
        fill="#006939"
      />
    </g>
  </StyledBreadcrumbSeparator>
);

export default BreadcrumbSeparator;
