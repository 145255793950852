import styled from "styled-components";
import { BREAKPOINTS, layoutSpacing, Wrapper } from "../shared";

export const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  background: #ffffff;
  padding-top: 24px;
  padding-bottom: 24px;
  ${layoutSpacing};

  @media (max-width: ${BREAKPOINTS.SM}) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const BackButton = styled.a`
  color: #006939;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
`;

export const HeaderWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
