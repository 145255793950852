import useApplication from "../../../../store/useApplication";
import { ProductsGrid } from "../../../../components/shared";
import ProductCard from "../../../../components/ProductCard";
import Details from "./Details";
import DownloadButton from "./DownloadButton";
import Downloads from "./Downloads";

const Package = () => {
  const packages = useApplication((state) => state.packages);

  return (
    <ProductsGrid>
      {packages.map(
        ({ volume, packageId, name, image, details, links, container }) => (
          <ProductCard
            title={name}
            subtitle={volume}
            picture={image}
            key={packageId}
            container={container}
          >
            <Details {...details} />
            <Downloads {...links} packageId={packageId} />
            <DownloadButton packageId={packageId} />
          </ProductCard>
        )
      )}
    </ProductsGrid>
  );
};

export default Package;
