import styled from "styled-components";

export const StyledRoundButton = styled.span`
  margin: 8px auto 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const StyledRoundButtonImage = styled.img`
  display: block;
  width: 24px;
  height: 24px;
`;
