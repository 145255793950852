import styled from "styled-components";
import { BREAKPOINTS, layoutSpacing, Wrapper } from "../../components/shared";

export const StyledProducts = styled.div`
  flex-grow: 1;
  ${layoutSpacing};
`;

export const ProductsWrapper = styled(Wrapper)`
  transition: 300ms ease all;
  padding-top: 68px;
  padding-bottom: 136px;
  flex-grow: 1;

  @media (max-width: ${BREAKPOINTS.SM}) {
    padding-bottom: 60px;
  }
`;
