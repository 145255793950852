import { RibbonText, StyledRibbon } from "./styles";

const Ribbon = () => {
  return (
    <StyledRibbon>
      <RibbonText>Nowość</RibbonText>
    </StyledRibbon>
  );
};

export default Ribbon;
