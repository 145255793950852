import useApplication from "../../store/useApplication";
import { PAGES } from "../../types";
import Logo from "../Logo";
import { BackButton, HeaderWrapper, StyledHeader } from "./styles";

const Header = () => {
  const setPage = useApplication((state) => state.setPage);
  const setBrandId = useApplication((state) => state.setBrandId);
  const setVariantId = useApplication((state) => state.setVariantId);
  const setVariants = useApplication((state) => state.setVariants);
  const setBreadcrumbs = useApplication((state) => state.setBreadcrumbs);
  const setPackages = useApplication((state) => state.setPackages);

  return (
    <StyledHeader>
      <HeaderWrapper>
        <Logo
          fillColor="#115622"
          onClick={() => {
            setPage(PAGES.BRAND);
            setBrandId(null);
            setVariantId(null);
            setBreadcrumbs([]);
            setVariants([]);
            setPackages([]);
          }}
        />
        <BackButton href="https://carlsbergpolska.pl/">
          CARLSBERG POLSKA
        </BackButton>
      </HeaderWrapper>
    </StyledHeader>
  );
};

export default Header;
