import { SocialItem, SocialLink, StyledSocials } from "./styles";

const items = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/CarlsbergPolska",
    img: "./images/fb-icon.svg",
  },
  {
    name: "LinkedIn",
    url: "https://pl.linkedin.com/company/carlsberg-group",
    img: "./images/in-icon.svg",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/user/carlsbergpolska",
    img: "./images/yt-icon.svg",
  },
];

const Socials = () => (
  <StyledSocials>
    {items.map(({ name, url, img }) => (
      <SocialLink key={name} href={url} target="_blank">
        <SocialItem src={img} />
      </SocialLink>
    ))}
  </StyledSocials>
);

export default Socials;
