import { useEffect } from "react";
import { getBrands } from "../../../api";
import useApplication from "../../../store/useApplication";
import PageContent from "../../../components/PageContent";

const Product = () => {
  const setBrands = useApplication((state) => state.setBrands);

  useEffect(() => {
    const fetchData = async () => {
      setBrands(await getBrands());
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageContent />;
};

export default Product;
