import { PackageDetails } from "../../../../../types";
import { Label } from "../../../../../components/shared";
import { DetailItem, StyledDetails } from "./styles";

const Details = ({ realSize, count, palletSize, number }: PackageDetails) => {
  const items = [
    { label: "Wymiary rzeczywiste (cm)", value: realSize },
    { label: "Opakowanie zbiorcze:", value: count },
    { label: "Sztuk na palecie:", value: palletSize },
    { label: "Kod EAN:", value: number },
  ];

  return (
    <StyledDetails>
      {items.map(({ label, value }) => (
        <DetailItem key={label}>
          {label} <Label>{value}</Label>
        </DetailItem>
      ))}
    </StyledDetails>
  );
};

export default Details;
