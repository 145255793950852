import { getVariants } from "../../../../api";
import useApplication from "../../../../store/useApplication";
import { PAGES } from "../../../../types";
import { ProductsGrid } from "../../../../components/shared";
import ProductCard from "../../../../components/ProductCard";

const Brand = () => {
  const brands = useApplication((state) => state.brands);
  const setPage = useApplication((state) => state.setPage);
  const setBrandId = useApplication((state) => state.setBrandId);
  const setBreadcrumbs = useApplication((state) => state.setBreadcrumbs);
  const setVariants = useApplication((state) => state.setVariants);

  return (
    <ProductsGrid>
      {brands.map(({ brandId, name, image }) => (
        <ProductCard
          key={brandId}
          picture={image}
          title={name}
          onClick={async () => {
            setPage(PAGES.VARIANT);
            setBrandId(brandId);
            const brand = brands.find((b) => b.brandId === brandId);
            setBreadcrumbs([brand]);
            setVariants(await getVariants(brandId));
          }}
        />
      ))}
    </ProductsGrid>
  );
};

export default Brand;
