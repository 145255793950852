import styled, { css } from "styled-components";

export const CARD_WIDTH = "278px";

export const BREAKPOINTS = {
  XL: "1199px",
  LG: "991px",
  MD: "767px",
  SM: "540px",
};

export const layoutSpacing = css`
  padding-left: 24px;
  padding-right: 24px;
`;

export const AppContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  transition: 300ms ease all;

  @media (max-width: ${BREAKPOINTS.SM}) {
    padding-top: 32px;
  }
`;

export const Layout = styled.div`
  transition: 300ms ease all;
  padding: 68px 0;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1390px;
  margin: auto;
`;

export const ProductsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${CARD_WIDTH}, max-content));
  justify-items: center;
  justify-content: center;
  grid-row-gap: 70px;
`;

export const Label = styled.span<{ isBold?: boolean }>`
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  font-weight: ${(p) => (p.isBold ? 700 : 400)};
`;
