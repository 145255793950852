import styled, { css } from "styled-components";
import { BREAKPOINTS } from "../shared";

export const StyledLogo = styled.svg`
  width: 86px;
  height: auto;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}

  @media (max-width: ${BREAKPOINTS.SM}) {
    width: 62px;
  }
`;
