import axios from "axios";
import useApplication from "../../../../../store/useApplication";
import { PackageId } from "../../../../../types";
import { StyledDownloadButton } from "./styles";
import { DownloadButtonProps } from "./types";

const FileDownload = require("js-file-download");

const DownloadButton = ({ packageId }: DownloadButtonProps) => {
  const setProgress = useApplication((state) => state.setProgress);
  const setDownloadIndicator = useApplication(
    (state) => state.setDownloadIndicator
  );
  const indicator = useApplication((state) => state.indicator);

  const download = (packageId: PackageId) => {
    setProgress("starting...");

    axios({
      url: `${process.env.REACT_APP_API_URI}/api/download/all/${packageId}`,
      responseType: "blob",
      onDownloadProgress(progress) {
        console.log("download progress:", progress);
      },
    }).then((response) => {
      FileDownload(response.data, "packshot.zip");
      setProgress("finished");
      setDownloadIndicator(indicator.filter((i: number) => i !== packageId));
    });
  };

  return (
    <StyledDownloadButton
      onClick={() => {
        setDownloadIndicator([...indicator, packageId]);
        download(packageId);
      }}
    >
      {indicator.includes(packageId) ? "Pobieram..." : "Pobierz wszystko "}
    </StyledDownloadButton>
  );
};

export default DownloadButton;
