export enum PAGES {
  BRAND = "brand",
  VARIANT = "variant",
  PACKAGE = "package",
}

export type BrandId = number | null;

export interface Brand {
  brandId: BrandId;
  container: string;
  image: string;
  order: number;
  name: string;
}

export type Brands = Brand[];

export type VariantId = number | null;

export type IsNew = "yes" | "no";

export interface Variant {
  variantId: VariantId;
  container: string;
  image: string;
  order: number;
  name: string;
  alcohol: string;
  isNew: IsNew;
}

export type Variants = Variant[];

export type PackageId = number | null;

export type LinkItem = {
  name: string;
  size: string;
};

export type PackageLinks = {
  tif: LinkItem;
  png: LinkItem;
  psd: LinkItem;
};

export type PackageDetails = {
  count: string;
  realSize: string;
  palletSize: string;
  number: string;
};

export interface Package {
  links: PackageLinks;
  details: PackageDetails;
  packageId: PackageId;
  container: string;
  image: string;
  order: number;
  name: string;
  variantId: string;
  volume: string;
}

export type Packages = Package[];
