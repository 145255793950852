import styled from "styled-components";

export const StyledSocials = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  grid-gap: 24px;
`;

export const SocialItem = styled.img`
  width: 100%;
`;

export const SocialLink = styled.a`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;
