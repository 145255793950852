import Breadcrumbs from "../../components/Breadcrumbs";
import PageHeader from "../../components/PageHeader";
import Product from "./Product";
import { ProductsWrapper, StyledProducts } from "./styles";

const Products = () => {
  return (
    <StyledProducts>
      <ProductsWrapper>
        <PageHeader />
        <Breadcrumbs />
        <Product />
      </ProductsWrapper>
    </StyledProducts>
  );
};

export default Products;
