import styled from "styled-components";

export const StyledDownloadButton = styled.a`
  width: calc(100% - 48px);
  display: flex;
  height: 40px;
  color: #5e6b6b;
  border-radius: 100px;
  background: #fff;
  border: 0;
  cursor: pointer;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #5e6b6b;
  align-items: center;
  justify-content: center;
  margin: 24px;
`;
