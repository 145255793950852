import create from "zustand";
import createApplicationSlice, {
  ApplicationSlice,
} from "./createApplicationSlice";
import createBrandSlice, { BrandSlice } from "./createBrandSlice";
import createPackageSlice, { PackageSlice } from "./createPackageSlice";
import createVariantSlice, { VariantSlice } from "./createVariantSlice";

export type MyState = ApplicationSlice &
  BrandSlice &
  VariantSlice &
  PackageSlice;

const useApplication = create<MyState>()((...a) => ({
  ...createApplicationSlice(...a),
  ...createBrandSlice(...a),
  ...createVariantSlice(...a),
  ...createPackageSlice(...a),
}));

export default useApplication;
