import Footer from "./components/Footer";
import Header from "./components/Header";
import { AppContainer } from "./components/shared";
import Products from "./pages/Products";
import Verify from "./pages/Verify";
import { useLocalStorage } from "@rehooks/local-storage";

const App = () => {
  const [number, setNum] = useLocalStorage("adult");

  const success = () => {
    setNum("true");
  };

  if (!number) {
    return <Verify callback={success} />;
  }

  return (
    <AppContainer>
      <Header />
      <Products />
      <Footer />
    </AppContainer>
  );
};

export default App;
