import styled, { css } from "styled-components";
import { BREAKPOINTS } from "../shared";

export const StyledBreadcrumbs = styled.div<{ isHidden?: boolean }>`
  display: ${(p) => (p.isHidden ? "none" : "flex")};
  align-items: center;
  margin-bottom: 146px;
  min-height: 22px;

  @media (max-width: ${BREAKPOINTS.SM}) {
    margin-bottom: 68px;
  }
`;

export const BreadcrumbLabel = styled.span<{ isClickable?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #006939;
  text-transform: uppercase;
  transition: 333ms ease all;

  ${(p) =>
    p.isClickable &&
    css`
      cursor: pointer;
      font-weight: 400;
    `}
`;

export const BreadcrumbsDesktop = styled.div`
  display: flex;
  grid-gap: 8px;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.SM}) {
    display: none;
  } ;
`;

export const BreadcrumbsMobile = styled.div`
  display: none;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #006939;

  @media (max-width: ${BREAKPOINTS.SM}) {
    display: grid;
  }
`;

export const BreadcrumbMobileButton = styled.div`
  display: flex;
  align-items: center;
`;
