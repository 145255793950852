import axios from "axios";
import useApplication from "../../../../../../store/useApplication";
import { PackageId } from "../../../../../../types";
import { formatBytes } from "../../../../../../components/utils";
import RoundButton from "../RoundButton";
import { FILES } from "../types";
import { ItemSize, ItemType, StyledDownloadItem } from "./styles";
import { DownloadItemProps } from "./types";

const FileDownload = require("js-file-download");

const DownloadItem = ({ packageId, size, type }: DownloadItemProps) => {
  const smallIndicator = useApplication((state) => state.smallIndicator);
  const setSmallIndicator = useApplication((state) => state.setSmallIndicator);

  const downloadSingle = (packageId: PackageId, type: FILES) => {
    axios({
      url: `${process.env.REACT_APP_API_URI}/api/download/index/${packageId}/${type}`,
      responseType: "blob", // Important
      onDownloadProgress(progress) {
        console.log("download progress:", progress);
      },
    }).then((response) => {
      setSmallIndicator(
        smallIndicator.filter((i) => i !== `${packageId}_${type}`)
      );

      const headerLine = response.headers["content-disposition"] as any;

      const startFileNameIndex = headerLine.indexOf('"') + 1;
      const endFileNameIndex = headerLine.lastIndexOf('"');

      const filename = headerLine.substring(
        startFileNameIndex,
        endFileNameIndex
      );

      FileDownload(response.data, filename);
    });
  };

  const packageType = `${packageId}_${type}`;

  return (
    <StyledDownloadItem>
      <ItemType>{type}</ItemType>
      <ItemSize>{formatBytes(size)}</ItemSize>
      <RoundButton
        isLoading={smallIndicator.includes(packageType)}
        onClick={() => {
          downloadSingle(packageId, type);
          setSmallIndicator([...smallIndicator, packageType]);
        }}
      />
    </StyledDownloadItem>
  );
};

export default DownloadItem;
