import styled from "styled-components";
import { BREAKPOINTS } from "../shared";

export const StyledPageHeader = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  line-height: 50px;
  color: #006939;
  text-transform: uppercase;
  margin: 0 0 72px;
  font-weight: 800;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.SM}) {
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 16px;
  }
`;
