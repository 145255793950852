import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 1280px;
  margin: auto;
`;

export const Data = styled.div`
  margin-top: 118px;
  text-align: center;
  width: 100%;

  @media (max-width: 835px) {
    margin-top: 86px;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  color: #fff;y
  font-weight: 700;
`;

export const Page = styled.div`
  height: 100vh;
  width: 100%;
  background: #182c27;
`;

export const LogoImage = styled.img`
  display: block;
  width: 100%;
  max-width: 120px;
`;

export const Age = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Warning = styled.p`
  font-size: 16px;
  color: #fff;

  padding: 0 16px;
  margin: 0 0 24px;
`;

export const Wrap = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  height: 226px;
  position: relative;
`;

export const Display = styled.div<{ isFilled: boolean; hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 186px;
  height: 226px;
  position: relative;
  background: #182c27;
  margin: 0 5px;
  font-size: 120px;
  border: 8px solid #396155;
  color: #fff;
  line-height: 0px;

  transition: 333ms ease all;

  ${(p) =>
    p.isFilled &&
    css`
      background: #284a41;
      border: 8px solid #284a41;

      @media (max-width: 835px) {
        border-width: 5px;
      }
    `}

  ${(p) =>
    p.hasError &&
    css`
      background: #284a41;
      border: 8px solid red;

      @media (max-width: 835px) {
        border-width: 5px;
      }
    `}

  @media(max-width: 835px) {
    width: 80px;
    height: 114px;
    font-size: 70px;
    border-width: 5px;
  }

  @media (max-width: 390px) {
    width: 70px;
  }
`;

export const MyInput = styled.input`
  position: absolute;
  border: none;
  font-size: 32px;

  background-color: transparent;
  outline: none;
  z-index: -9999;
  top: -9999px;
  left: -9999px;
`;

export const Shadows = styled.div`
  position: absolute;
  display: inline-block;
  left: -8px;
  top: -8px;
  bottom: 0;
  right: 0;
  border: 8px solid #536e67;
  width: 186px;
  height: 226px;
  font-size: 120px;
  line-height: 0;
  z-index: 1;
  transition: 333ms ease all;

  @media (max-width: 835px) {
    width: 80px;
    height: 114px;
    font-size: 70px;
    border-width: 5px;
    left: -5px;
    top: -5px;
  }

  @media (max-width: 390px) {
    width: 70px;
  }

  &:after {
    content: "_";
    position: absolute;
    left: 0;
    right: 0;

    top: 60px;
    animation: blink normal 1.5s infinite ease-in-out;
    height: 100%;
    top: 120px;

    @media (max-width: 835px) {
      top: 60px;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Error = styled.div`
  margin-top: 16px;
  height: 20px;
  margin-bottom: 32px;
`;

export const StyledVerification = styled.div`
  padding: 40px 20px 60px;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #182c27;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  z-index: 99999999;
  overflow-x: hidden;
  overflow-y: auto;
  color: #fff;
  font-size: 16px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const Numbers = styled.div`
  overflow: hidden;
  width: 100%;
  height: 226px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 835px) {
    height: 114px;
  }
`;

export const Slider = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AgeGrid = styled.div`
  justify-content: center;
  position: relative;
  top: 0;
  height: 100%;
  display: flex;
`;
