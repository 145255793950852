import styled, { css } from "styled-components";

enum STATES {
  ENTERING = "entering",
  ENTERED = "entered",
  EXITING = "exiting",
  EXITED = "exited",
}

export const Animated = styled.div<{ state?: any }>`
  transition: 300ms ease all;
  opacity: 0;

  ${(p) =>
    p.state === STATES.ENTERING &&
    css`
      transform: translateX(-100px);
    `}

  ${(p) =>
    p.state === STATES.ENTERED &&
    css`
      opacity: 1;
      transform: translateX(0px);
    `}

    ${(p) =>
    p.state === STATES.EXITING &&
    css`
      transform: translateX(-100px);
    `}

    ${(p) =>
    p.state === STATES.EXITED &&
    css`
      opacity: 0;
      transform: translateX(-100px);
    `}
`;
