import { PackageId, PackageLinks } from "../../../../../types";

export interface DownloadsProps extends PackageLinks {
  packageId: PackageId;
}

export enum FILES {
  PNG = "png",
  TIF = "tif",
  PSD = "psd",
}
