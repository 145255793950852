import { StateCreator } from "zustand";
import { PAGES } from "../types";
import { MyState } from "./useApplication";

export interface ApplicationSlice {
  page: PAGES;

  setPage: (page: PAGES) => void;

  breadcrumbs: any[];

  setBreadcrumbs: (breadcrumbs: any) => void;
  number: string;
  setNum: (data: string) => void;
  progress: string;
  setProgress: (data: string) => void;
  indicator: any[];
  setDownloadIndicator: (data: any) => void;
  smallIndicator: any[];
  setSmallIndicator: (data: any) => void;
}

export const createApplicationSlice: StateCreator<
  MyState,
  [],
  [],
  ApplicationSlice
> = (set) => ({
  page: PAGES.BRAND,
  breadcrumbs: [],
  setPage: (type) =>
    set((state) => {
      return {
        ...state,
        page: type,
      };
    }),

  setBreadcrumbs: (breadcrumbs) =>
    set((state) => {
      return {
        ...state,
        breadcrumbs,
      };
    }),
  number: "",
  // number: useLocalStorage("adult"),
  setNum: (number) =>
    set((state) => {
      return {
        ...state,
        number,
      };
    }),
  progress: "idle",
  setProgress: (progress) =>
    set((state) => {
      return {
        ...state,
        progress,
      };
    }),
  indicator: [],
  setDownloadIndicator: (indicator) =>
    set((state) => {
      return {
        ...state,
        indicator,
      };
    }),
  smallIndicator: [],
  setSmallIndicator: (smallIndicator) =>
    set((state) => {
      return {
        ...state,
        smallIndicator,
      };
    }),
});

export default createApplicationSlice;
