import { useRef, useState } from "react";
import {
  AgeGrid,
  Data,
  Display,
  Error,
  FlexContainer,
  LogoImage,
  MyInput,
  Numbers,
  Shadows,
  Slider,
  StyledVerification,
  Title,
  Warning,
} from "./styles";

const CODE_LENGTH = new Array(4).fill(0);

const Verify = (props: any) => {
  const [state, setState] = useState({
    value: "19",
    focused: false,
    error: false,
    errorMessage: "",
  });

  const { value, focused } = state;

  const values = value?.split("");

  const input = useRef(null);

  const handleClick = () => {
    (input.current as any).focus();
  };

  const handleFocus = () => {
    setState({ ...state, focused: true });
  };

  const handleBlur = () => {
    setState({
      ...state,
      focused: false,
    });
  };

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;

    let error = false;
    let errorMessage = "";

    const inputValue = state.value + value;
    const r = new Date();

    if (inputValue.length > 4) return false;

    if (
      inputValue.length === CODE_LENGTH.length &&
      Number(inputValue) <= 1870
    ) {
      error = true;
      errorMessage = "Niepoprawny rok";
    }

    if (Number(inputValue) >= r.getFullYear()) {
      error = true;
      errorMessage = "Niepoprawny rok";
    }

    if (
      r.getFullYear() - Number(inputValue) <= 18 &&
      Number(inputValue) <= r.getFullYear()
    ) {
      error = true;
      errorMessage = "Nie masz ukończone 18 lat";
    }

    const regex = /^[0-9]{4}$/g;

    if (regex.test(inputValue) && !error) {
      props.callback();
    }

    setState({
      ...state,
      value: inputValue.slice(0, CODE_LENGTH.length),
      error,
      errorMessage,
    });
  };

  const handleKeyUp = (e: { key: string }) => {
    if (e.key === "Backspace") {
      setState({
        ...state,
        value: state.value.slice(0, state.value.length - 1),
        error: false,
        errorMessage: "",
      });
    }
  };

  return (
    <StyledVerification>
      <FlexContainer>
        <LogoImage
          src={
            "https://carlsbergpolska.pl/media/11094/carlsberg-polska_white.png"
          }
        />

        <Data>
          <Title>Podaj rok swoich urodzin </Title>

          <Numbers onClick={handleClick}>
            <Slider>
              <AgeGrid>
                {CODE_LENGTH.map((v, index) => {
                  const selected = values.length === index;
                  const isComplete = values[index] ? true : false;

                  const filled =
                    values.length === CODE_LENGTH.length &&
                    index === CODE_LENGTH.length - 1;

                  return (
                    <Display isFilled={isComplete} hasError={state.error}>
                      {values[index]}
                      {filled}

                      {(selected || filled) && focused && !state.error && (
                        <Shadows />
                      )}
                    </Display>
                  );
                })}
                <MyInput
                  type="text"
                  value=""
                  ref={input}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  autoFocus={true}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  maxLength={1}
                  autoComplete="off"
                />
              </AgeGrid>
            </Slider>
          </Numbers>

          <Error>
            {state.error && values.length === CODE_LENGTH.length && (
              <Warning>{state.errorMessage}</Warning>
            )}
          </Error>
          <Warning>
            Aby wejść na tę stronę musisz posiadać co najmniej 18 lat.
          </Warning>
        </Data>
      </FlexContainer>
    </StyledVerification>
  );
};

export default Verify;
