import DownloadItem from "./DownloadItem";
import { StyledDownloads } from "./styles";
import { DownloadsProps, FILES } from "./types";

const Downloads = ({ packageId, psd, tif, png }: DownloadsProps) => {
  return (
    <StyledDownloads>
      {psd.size && (
        <DownloadItem type={FILES.PSD} size={psd.size} packageId={packageId} />
      )}
      {tif.size && (
        <DownloadItem type={FILES.TIF} size={tif.size} packageId={packageId} />
      )}
      {png.size && (
        <DownloadItem type={FILES.PNG} size={png.size} packageId={packageId} />
      )}
    </StyledDownloads>
  );
};

export default Downloads;
