import { Fragment } from "react";
import Ribbon from "../Ribbon";
import { Label } from "../shared";
import { Picutre, PicutreChild, StyledProductCard, Title } from "./styles";
import { ProductCardProps } from "./types";

const ProductCard = ({
  onClick,
  children,
  picture,
  className,
  title,
  subtitle,
  isNew,
  container,
}: ProductCardProps) => (
  <StyledProductCard onClick={onClick} className={className} isNew={isNew}>
    {isNew === "yes" && <Ribbon />}
    <Picutre>
      <PicutreChild
        container={container}
        src={`${process.env.REACT_APP_UPLOADS_PATH}/uploads/${picture}`}
      />
    </Picutre>
    <Title>{title}</Title>
    {subtitle && <Label>{subtitle}</Label>}
    {children && <Fragment>{children}</Fragment>}
  </StyledProductCard>
);

export default ProductCard;
