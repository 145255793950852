import styled from "styled-components";
import { BREAKPOINTS } from "../../shared";

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
`;

export const Links = styled.ul`
  display: flex;
  align-items: center;
  grid-gap: 32px;
  padding: 0;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.SM}) {
    flex-direction: column;
    grid-gap: 18px;
  }
`;

export const Link = styled.a`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
`;

export const Copyrights = styled.p`
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
`;
