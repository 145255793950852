import styled from "styled-components";
import { Label } from "../../../../../../components/shared";

export const StyledDownloadItem = styled(Label)`
  display: flex;
  flex-direction: column;
`;

export const ItemType = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

export const ItemSize = styled.span`
  font-weight: 400;
`;
