import styled from "styled-components";

export const StyledDownloads = styled.div`
  display: grid;
  grid-row-gap: 24px;
  grid-template-columns: repeat(2, 50%);
  text-align: center;
  width: calc(100% - 32px);
  padding: 24px 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
`;
