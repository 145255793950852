import { StateCreator } from "zustand";
import { Packages } from "../../types";
import { MyState } from "../useApplication";

export interface PackageSlice {
  packages: Packages;
  setPackages: (packages: Packages) => void;
}

export const createPackageSlice: StateCreator<MyState, [], [], PackageSlice> = (
  set
) => ({
  packages: [],
  setPackages: (packages) =>
    set((state) => {
      return {
        ...state,
        packages,
      };
    }),
});

export default createPackageSlice;
