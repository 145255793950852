import Logo from "../Logo";
import Menu from "./Menu";
import { Copyrights } from "./Menu/styles";
import Socials from "./Socials";
import {
  CopyrightsArea,
  FooterGrid,
  FooterWrapper,
  LogoArea,
  MenuArea,
  SocialsArea,
  StyledFooter,
} from "./styles";

const Footer = () => (
  <StyledFooter>
    <FooterWrapper>
      <FooterGrid>
        <LogoArea>
          <Logo fillColor="white" />
        </LogoArea>
        <CopyrightsArea>
          <Copyrights>© 2020 CARLSBERG POLSKA. All rights reserved.</Copyrights>
        </CopyrightsArea>
        <MenuArea>
          <Menu />
        </MenuArea>
        <SocialsArea>
          <Socials />
        </SocialsArea>
      </FooterGrid>
    </FooterWrapper>
  </StyledFooter>
);

export default Footer;
