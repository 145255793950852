import { getPackages } from "../../../../api";
import useApplication from "../../../../store/useApplication";
import { PAGES } from "../../../../types";
import { ProductsGrid } from "../../../../components/shared";
import ProductCard from "../../../../components/ProductCard";

const Variant = () => {
  const variants = useApplication((state) => state.variants);
  const setPage = useApplication((state) => state.setPage);
  const setVariantId = useApplication((state) => state.setVariantId);
  const setBreadcrumbs = useApplication((state) => state.setBreadcrumbs);
  const breadcrumbs = useApplication((state) => state.breadcrumbs);
  const setPackages = useApplication((state) => state).setPackages;

  return (
    <ProductsGrid>
      {variants.map(({ variantId, name, alcohol, image, isNew }) => (
        <ProductCard
          title={name}
          subtitle={`${alcohol}%`}
          picture={image}
          key={variantId}
          isNew={isNew}
          onClick={async () => {
            setPage(PAGES.PACKAGE);
            setVariantId(variantId);
            const variant = variants.find((v) => v.variantId === variantId);
            setBreadcrumbs([...breadcrumbs, variant]);
            setPackages(await getPackages(variantId));
          }}
        />
      ))}
    </ProductsGrid>
  );
};

export default Variant;
